@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    overflow-x: hidden;
    font-family: Poppins, sans-serif;
}


.copied:after {
    width: max-content;
    content: 'Copied!';
    position: absolute;
    top: 3px;
    right: 3px;
    background: #e3e3e3;
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-size: 0.8rem;
    font-weight: 600;
    color: #000;
}


.thin-scroll::-webkit-scrollbar
{
	width: 2px;
    height: 4px;
	background-color: #F5F5F5;
}

.thin-scroll::-webkit-scrollbar-thumb
{
	background-color: #a12e2e;
}

#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px #a12e2e;
	background-color: #F5F5F5;
}